<template>
  <div>
    <div :class="getStatusClass(status)">
      <div class="badge-text-sm">
        {{ getStatus() }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  methods: {
    getStatus() {
      if (this.status.restToPay <= 0 || null !== this.status.consideredAsPaidAt) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.table.paid');
      }
      if (this.status.restToPay === this.status.price) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.table.not-paid');
      }
      return this.$t('components.custom.planning.booking-details-modal.general-information.table.pending');
    },
    getStatusClass(status) {
      if (this.status.restToPay <= 0 || null !== this.status.consideredAsPaidAt) {
        return 'badge-available-success';
      }
      if (status.restToPay === status.price) {
        return 'badge-available-error';
      }
      if (status.restToPay < status.price) {
        return 'badge-available-pending';
      }
    }
  }
}
</script>
